import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  ActivatedRouteSnapshot, CanActivate,


  Router, RouterStateSnapshot
} from "@angular/router";
import { UserType } from "_models";
import { AuthenticationService } from "_services";

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private snack: MatSnackBar,
    public auth: AuthenticationService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.auth.currentUserValue;
    if (currentUser) {
      if (route.data &&
        route.data.roles &&
        route.data.roles.includes(UserType[currentUser.userType])) {
        return true;
      }

      this.router.navigate(['']);
    }

    return false;
  }
}
