<div class="header-navigation" fxFlex="100" fxLayoutAlign="end center">

  <button class="menu-button" mat-button [matMenuTriggerFor]="navMenu" fxHide="true" fxShow.lt-md="true">
    <mat-icon>menu</mat-icon>Menu
  </button>
  <mat-menu #navMenu="matMenu">
    <button mat-menu-item routerLink="/" routerLinkActive="selected"
      [routerLinkActiveOptions]="{exact:true}">{{'LAYOUT.HOME' | translate}}</button>
    <button mat-menu-item routerLink="/company" routerLinkActive="selected">{{'LAYOUT.COMPANY' | translate}}</button>
    <button mat-menu-item routerLink="/numbers" routerLinkActive="selected">{{'LAYOUT.NUMBERS' | translate}}</button>
    <button mat-menu-item routerLink="/sustainability" routerLinkActive="selected">{{'LAYOUT.SUSTAINABILITY' |
      translate}}</button>
    <button mat-menu-item routerLink="/products" routerLinkActive="selected">{{'LAYOUT.PRODUCTS' |
      translate}}</button>
    <button mat-menu-item routerLink="/quick-service" routerLinkActive="selected">{{'LAYOUT.QUICK_SERVICE' |
      translate}}</button>
    <button mat-menu-item routerLink="/wool" routerLinkActive="selected">{{'LAYOUT.WOOL' | translate}}</button>
    <button mat-menu-item routerLink="/news" routerLinkActive="selected">{{'LAYOUT.NEWS_EVENTS' | translate}}</button>
    <button mat-menu-item routerLink="/contacts" routerLinkActive="selected">{{'LAYOUT.CONTACTS' |
      translate}}</button>
  </mat-menu>


  <div class="menu-bar" fxFlex="100" fxShow="true" fxHide.lt-md="true">
    <!-- HOME -->
    <button mat-button routerLink="/" routerLinkActive="selected"
      [routerLinkActiveOptions]="{exact:true}">{{'LAYOUT.HOME' | translate}}</button>

    <!-- COMPANY -->
    <button mat-button routerLink="/company" routerLinkActive="selected">{{'LAYOUT.COMPANY' | translate}}</button>

    <!-- NÚMEROS -->
    <button mat-button routerLink="/numbers" routerLinkActive="selected">{{'LAYOUT.NUMBERS' | translate}}</button>

    <!-- SUSTENTABILIDADE -->
    <button mat-button routerLink="/sustainability" routerLinkActive="selected">{{'LAYOUT.SUSTAINABILITY' |
      translate}}</button>

    <!-- PRODUTOS -->
    <button mat-button routerLink="/products" routerLinkActive="selected">{{'LAYOUT.PRODUCTS' | translate}}</button>

    <!-- QUICK SERVICE -->
    <button mat-button routerLink="/quick-service" routerLinkActive="selected">{{'LAYOUT.QUICK_SERVICE' |
      translate}}</button>

    <!-- WOOL -->
    <button mat-button routerLink="/wool" routerLinkActive="selected">{{'LAYOUT.WOOL' | translate}}</button>

    <!-- NOTÍCIAS/EVENTOS -->
    <button mat-button routerLink="/news" routerLinkActive="selected">{{'LAYOUT.NEWS_EVENTS' | translate}}</button>

    <!-- CONTACTOS -->
    <button mat-button routerLink="/contacts" routerLinkActive="selected">{{'LAYOUT.CONTACTS' | translate}}</button>

  </div>
</div>