
export const environment = {
  production: false,
  apiURL: 'https://siteapi.paulo-oliveira.pt/api/',
  conceptStorage: "https://siteimg.paulo-oliveira.pt/concepts/",
  subconceptStorage: "https://siteimg.paulo-oliveira.pt/subconcepts/",
  productStorage: "https://siteimg.paulo-oliveira.pt/products/",
  collectionStorage: "https://siteimg.paulo-oliveira.pt/collections/",
  purchasePdfStorage: "https://siteimg.paulo-oliveira.pt/purchases/",
  newsStorage: "https://siteimg.paulo-oliveira.pt/news/",
  eventsStorage: "https://siteimg.paulo-oliveira.pt/events/",
  slideshowStorage: "https://siteimg.paulo-oliveira.pt/slideshow/",
  videoStorage: "https://siteimg.paulo-oliveira.pt/video/",
  certificatesStorage: "https://siteimg.paulo-oliveira.pt/certificates/",
  numberStorage: "https://siteimg.paulo-oliveira.pt/numbers/",
  projectStorage: "https://siteimg.paulo-oliveira.pt/projects/",
  socialStorage: "https://siteimg.paulo-oliveira.pt/socials/",
  historyStorage: "https://siteimg.paulo-oliveira.pt/history/",
  sustainabilityStorage: "https://siteimg.paulo-oliveira.pt/sustainability/"
};
