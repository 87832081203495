import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TwoFactorAuthentication } from 'app/shared/components/two-factor-authentication/two-factor-authentication';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SharedDirectivesModule } from '../directives/shared-directives.module';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { SearchModule } from '../search/search.module';
import { AppComfirmComponent } from '../services/app-confirm/app-confirm.component';
import { AppLoaderComponent } from '../services/app-loader/app-loader.component';
import { SharedMaterialModule } from '../shared-material.module';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
// ONLY FOR DEMO
import { EgretSidebarComponent, EgretSidebarTogglerDirective } from './egret-sidebar/egret-sidebar.component';
import { FooterComponent } from './footer/footer.component';
// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import { HeaderSideComponent } from './header-side/header-side.component';
// ALWAYS REQUIRED 
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { FooterPdfComponent } from './purchase-pdf/footer/footer-pdf.component';
import { HeaderPdfComponent } from './purchase-pdf/header/header-pdf.component';
import { PurchasesPdfComponent } from './purchase-pdf/purchase-pdf.component';
import { SidebarSideComponent } from './sidebar-side/sidebar-side.component';
import { SidebarTopComponent } from './sidebar-top/sidebar-top.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { TitleHeaderComponent } from './title-header/title-header.component';
import { TopFixedBarComponent } from './top-fixed-bar/top-fixed-bar.component';
import { TopHeaderBarComponent } from './top-header-bar/top-header-bar.component';
import { TopNavBarComponent } from './top-nav-bar/top-nav-bar.component';







const components = [
  SidebarTopComponent,
  SidenavComponent,
  SidebarSideComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  HomeLayoutComponent,
  AuthLayoutComponent,
  BreadcrumbComponent,
  AppComfirmComponent,
  AppLoaderComponent,
  EgretSidebarComponent,
  FooterComponent,
  EgretSidebarTogglerDirective,
  TwoFactorAuthentication,
  PurchasesPdfComponent,
  HeaderPdfComponent,
  FooterPdfComponent,
  NewsletterComponent,
  TopFixedBarComponent,
  TopHeaderBarComponent,
  TopNavBarComponent,
  TitleHeaderComponent
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    SearchModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    SharedMaterialModule,
  ],
  declarations: components,
  exports: components
})
export class SharedComponentsModule { }