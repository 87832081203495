import { Configuration } from "./configuration.interface";

export const configuration: Configuration = {
    name: "Paulo de Oliveira",
    long_name: "Paulo de Oliveira S.A.",
    url: "https://www.paulo-oliveira.pt/",
    logo_path: "/assets/images/logos/logo-icon.png",
    logo_path_inverse_color: "/assets/images/logos/logo-icon-white.png",
    auth_logo: "/assets/images/logos/logo.png",
    phone_number: "+ 351 275 320 200",
    fax: "+ 351 275 320 201",
    email: "oliveira@paulo-oliveira.pt",
    address: {
        line1: "P.O. BOX 157 - C.C. Quinta das Mineiras",
        line2: "Boidobra 6201-951 | Covilhã - Portugal"
    },
    coordinates: "40.253950, -7.496206",
    css: {
        mainColor: "#f90100",
    },
    social_links: {
        facebook: "https://www.facebook.com/PauloDeOliveiraSA.Lanificio",
        instagram: "https://www.instagram.com/paulooliveirasa.lanificio",
        twitter: "https://twitter.com/Paulo_Lanificio",
        linkedin: "https://www.linkedin.com/in/paulo-de-oliveira-sa"
    },
};
